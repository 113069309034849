import React, { useEffect, useState, useRef, useCallback } from 'react'
import { HomeIcon } from '@heroicons/react/20/solid'
import { useRouter } from 'next/router'
import { useViewport } from '@/hooks/useViewport'
import { cn } from '@/lib/utils'

export type BreadcrumbItem = {
  name: string
  href?: string | null
  onClick?: () => void
  current: boolean
}

type Props = {
  items: BreadcrumbItem[]
  classnamesContainer?: string
  noBorder?: boolean
  showSkeleton?: boolean
  timeoutSkeleton?: number
}

const Breadcrumb: React.FC<Props> = ({
  items,
  classnamesContainer = '',
  noBorder = false,
  showSkeleton = false,
  timeoutSkeleton = 200
}) => {
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(true)
  const breadcrumbRef = useRef<HTMLOListElement>(null)
  const { breakpoint } = useViewport()
  const { pathname, push } = useRouter()

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoadingSkeleton(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoadingSkeleton(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const scrollToEnd = useCallback(() => {
    if (breadcrumbRef.current) {
      breadcrumbRef.current.scrollLeft = breadcrumbRef.current.scrollWidth
    }
  }, [])

  useEffect(() => {
    const checkBreadcrumbRef = () => {
      if (breadcrumbRef.current) {
        scrollToEnd()
        return true
      }
      return false
    }

    const intervalId = setInterval(() => {
      if (checkBreadcrumbRef()) {
        clearInterval(intervalId)
      }
    }, 100)
    return () => clearInterval(intervalId)
  }, [breakpoint, scrollToEnd, items])

  const handleClickHome = useCallback(() => {
    const isHome = pathname === '/'
    if (isHome) {
      window.location.reload()
    }
    push('/')
  }, [pathname, push])

  if (showSkeleton || isLoadingSkeleton) {
    return (
      <nav
        className={`flex border-b border-gray-200 bg-accent-100 dark:bg-gray-100 ${
          noBorder ? 'border-none' : ''
        }`}
        aria-label="Breadcrumb"
      >
        <div className={cn('container-main', classnamesContainer)}>
          <ol
            role="list"
            className="flex w-full px-0 space-x-4 overflow-hidden overflow-x-auto"
          >
            <li className="sticky flex">
              <div className="flex items-center">
                <div className="flex items-center w-6 h-6 skeleton"></div>
              </div>
            </li>
            {[...Array(2)].map((_, index) => (
              <li key={`breadcrumb-item-${index}`} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <div className="flex items-center w-20 h-5 ml-4 skeleton"></div>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </nav>
    )
  }

  return (
    <nav
      className={cn(
        'flex border-b border-gray-200 bg-accent-100 dark:bg-gray-100',
        noBorder ? 'border-none' : ''
      )}
      aria-label="Breadcrumb"
    >
      <div className={cn('container-main', classnamesContainer)}>
        <ol
          role="list"
          className="flex w-full px-0 space-x-4 overflow-hidden overflow-x-auto"
          ref={breadcrumbRef}
        >
          <li className="sticky flex">
            <div className="flex items-center">
              <button
                type="button"
                className="text-gray-500 cursor-pointer hover:text-gray-800"
                onClick={handleClickHome}
              >
                <HomeIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
          {items.map((item, index) => (
            <li key={`breadcrumb-item-${index}`} className="flex">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>

                {item.href || item.onClick ? (
                  <button
                    onClick={() => {
                      const isRouteActive = pathname === item.href
                      if (isRouteActive) {
                        window.location.reload()
                        return
                      }
                      if (item.href) {
                        push(item.href)
                        return
                      }
                      item.onClick && item.onClick()
                    }}
                    className={cn(
                      item.current
                        ? 'text-gray-700 hover:text-gray-800'
                        : 'text-gray-500 hover:text-gray-800',
                      'ml-4 text-sm font-medium whitespace-nowrap cursor-pointer'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </button>
                ) : (
                  <span
                    className="ml-4 text-sm font-medium text-gray-500 whitespace-nowrap"
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </span>
                )}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </nav>
  )
}

export default Breadcrumb
